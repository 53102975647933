import React from "react";

import showcaseCss from "./showcase.module.css";

import ShowcaseCard from "./showcase-card";

const showcaseTitleStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Showcase = ({ images }) => (
  <div className={showcaseCss.wrapper}>
    <div style={showcaseTitleStyles}>
      <h2>Realizacje</h2>
    </div>
    {images.map(({ node }) => (
      <ShowcaseCard key={node.id} image={node} />
    ))}
  </div>
);

export default Showcase;
