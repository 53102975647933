import React from "react";

import Img from "gatsby-image";

import showcaseCss from './showcase.module.css';

const ShowcaseCard = ({ image }) => {
  return (
    <div className={showcaseCss.card}>
      <Img
      imgStyle={{borderRadius: ".5rem .5rem 0 0"}}
        fluid={image.childImageSharp.fluid}
        alt="Conference room with blinds covering the windows."
      />
      <article style={{padding: "0 2rem 2rem"}}>
        <h3 style={{paddingBottom: "1rem", borderBottom: " 1px solid #2e3141"}}>{image?.meta?.title}</h3>
        <p>{image?.meta?.description}</p>
      </article>
    </div>
  );
};

export default ShowcaseCard;
