import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { prefix } from "../components/offer-section.utils";
import offerDetailsCss from "../components/offer-details.module.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import Showcase from "../components/showcase";

// style
const pStyles = {
  lineHeight: "1.4rem",
  margin: "1rem 0",
};

const background = "#20222d";

// data
const meta = [
  {
    img: "moskitiera-drzwiowa-2",
    text: {
      title: "Moskitiera drzwiowa",
      description:
        "drzwi moskitierowe idealne na każdy balkon, taras, w ofercie z przejściem dla psa i kota",
    },
  },
  {
    img: "moskitiera-drzwiowa-1",
    text: {
      title: "Moskitiera przesuwna",
      description:
        "moskitiera plisowana, najlepsze zastosowanie przy roletach zewnętrznych",
    },
  },
  {
    img: "moskitiera-drzwiowa-3",
    text: {
      title: "Drzwi moskitierowe",
      description: "drzwi moskitierowe",
    },
  },
  {
    img: "moskitiera-okienna-1",
    text: {
      title: "Moskitiera ramkowa",
      description: "moskitiera ramkowa, tanie rozwiązanie dla każdego okna",
    },
  },
];

// markup
const MoskitieryPage = () => {
  // data
  const descriptions = [
    "Moskitiery stanowią całoroczną ochroną wnętrz naszych domów przed uciążliwymi owadami. Pozwalają one na pozostawienie uchylonych, bądź otwartych okien i drzwi bez obaw o inwazję insektów. W naszej ofercie znajdują się tradycyjne moskitiery ramkowe, rolowane. Nowością są również plisowane. Ich cechą charakterystyczną jest możliwość zamontowania w formie drzwi przesuwnych, dzięki czemu nie kolidują one z roletami zewnętrznymi.",
    "Głównym zadaniem moskitier jest powstrzymanie owadów przed dostaniem się do środka naszych domostw, jednakże poza tą funkcją, ograniczają również ilość pyłków trafiających do naszych wnętrz. Jest to bardzo ważna informacja dla alergików i astmatyków, którzy z pewnością docenią tą cechę w okresie pylenia roślin. Różnicę w czystości powietrza zauważy każdy, kto wejdzie do pomieszczenia z moskitierą.",
  ];

  const { images } = useStaticQuery(graphql`
    query MoskitieryImages {
      images: allFile(
        filter: { relativeDirectory: { eq: "showcase/moskitiery" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const final = images.edges.map((edge) => {
    const texts = meta.find((m) => m.img === edge?.node?.name);
    edge.node.meta = { ...texts?.text };
    return edge;
  });
  return (
    <Layout>
      <SEO
        title="Rolety Lider | Moskitiery | Moskitiery drzwiowe | Moskitiery okienne | Moskitiery ramkowe"
        description="Moskitiery na wymiar. Tylko sprawdzeni producenci. Szeroki wybór typów: drzwiowe, okienne, ramkowe, metalowe, drewniane"
      />
      <section style={{ marginTop: "-6.1rem" }}>
        {prefix(true, background)}
        <article
          style={{
            background,
          }}
          className={offerDetailsCss.article}
        >
          <PageTitle showBack="true" title="Moskitiery" />
          <div className={offerDetailsCss.text}>
            {descriptions.map((text, i) => (
              <p key={i} style={pStyles}>
                {text}
              </p>
            ))}
          </div>
          <Showcase images={final} />
        </article>
      </section>
    </Layout>
  );
};

export default MoskitieryPage;
